import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import BaseBlocksRenderer from "../components/blocks/base-blocks-renderer"
import Layout from "../components/layout"
import Seo from "../components/seo"

const FlotaVoltio = () => {
  const { strapiVoltioFleet } = useStaticQuery(graphql`
    query {
      strapiVoltioFleet {
        defaultSeo {
          metaTitle
          metaDescription
          shareImage {
            id
            url
            localFile {
              url
            }
          }
        }
        blocks {
          __typename
          ... on STRAPI__COMPONENT_SHARED_HEADER {
            title
            cols
            isLarge
            cover {
              alternativeText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ...BaseSectionTitle
          ... on STRAPI__COMPONENT_FLOTA_VOLTIO_CARS_FLEET_LIST {
            cars {
              id
              title
              description {
                data {
                  childMarkdownRemark {
                    html
                  }
                }
              }
              image {
                alternativeText
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
          ... on STRAPI__COMPONENT_SHARED_MEDIA {
            id
            file {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          ... on STRAPI__COMPONENT_FLOTA_VOLTIO_FLEET {
            id
            carModel {
              id
              sectionTitle
              highlight
              level
              highlightClass
              note
              className
            }
            subtitle {
              subsectionTitle
            }
            features {
              id
              iconSubtitle
              hasBg
              fixedHeight
              cover {
                url
              }
            }
            subtitleInstructions {
              subsectionTitle
            }
            media {
              files {
                id
                mime
                alternativeText
                localFile {
                  extension
                  url
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { defaultSeo, blocks } = strapiVoltioFleet

  return (
    <Layout>
      <Seo seo={{ ...defaultSeo, url: "/flota-voltio" }} />
      <BaseBlocksRenderer blocks={blocks} />
    </Layout>
  )
}

export default FlotaVoltio
